//
// ─── CSS GLOBAL ───────────────────────────────────────
//
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Poppins', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "politica-condensed", sans-serif;
  font-weight: 900;
  font-style: normal;
  color: var(--grey);
}

a {
  text-decoration: none;
  color: black;
}

:root {
  --background: #f6f4f3;
  --primary: #b9b5b5;
  --secondary: #d5cfcf;
  --orange-primary: #e17f51;
  --orange-secondary: #f6986c;
  --black: #1a1a1a;
  --grey: #707070;
  --font: #757474;
  --outlined: #edc7b7;
  --font-navbar: #C5CEE0;
  --arrow: #C3C3C3
}

//
// ─── BUTTON CSS ───────────────────────────────────────
//
// Container 
.buttonContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;

  & .button {
    align-items: center;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 80px;
  }
}

.button {
  // Layout
  padding: 8px 20px;
  margin: 10px auto;
  width: 60%;
  text-align: center;

  // Font
  font-family: 'Poppins', sans-serif;
  font-size: 15px;
  letter-spacing: 1px;
  color: white;

  // Borders
  border-radius: 15px;
  border: none;

  // Box shadow
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.primary {
  // Layout
  background: var(--primary);

  // Font
  font-weight: 700;
  text-transform: capitalize;

  >:hover {
    background: var(--secondary);
  }
}

.secondary {
  // Layout
  background: var(--secondary);

  // Font
  font-weight: 300;

  >:hover {
    background: var(--primary);
  }
}

.third {
  // Layout
  background: var(--orange-secondary);

  // Font
  font-weight: 300;

  >:hover {
    background: var(--orange-primary);
  }
}

.fourth {
  // Layout
  background: var(--orange-secondary);

  // Font
  font-weight: 500;
  //text-transform: uppercase;

  >:hover {
    background: var(--orange-primary);
  }
}

.cta {
  // Layout
  background: var(--orange-secondary);
  text-align: center;

  // Width
  width: fit-content;

  // Margin
  margin: 25px auto;

  // Font
  font-weight: 500;
  //text-transform: uppercase;

  >:hover {
    background: var(--orange-primary);
  }
}

.m-top {
  margin-top: 0px;
}

//
// ──────────────────────────────────────────
//

//
// ─── CHECKBOX CSS ───────────────────────────────────────
//
.inputCheckbox {
  /* Add if not using autoprefixer */
  -webkit-appearance: none;
  appearance: none;
  /* For iOS < 15 to remove gradient background */
  background-color: var(--background);
  /* Not removed via appearance */
  margin: 0;
  font: inherit;
  color: var(--orange-primary);
  width: 1.30em;
  height: 1.30em;
  border: 0.15em solid var(--orange-primary);
  border-radius: 0.15em;
  display: grid;
  transform: translateY(-0.075em);
  place-content: center;
}

// .inputCheckbox:disabled {
//   /* Add if not using autoprefixer */
//   -webkit-appearance: none;
//   appearance: none;
//   /* For iOS < 15 to remove gradient background */
//   background-color: var(--background);
//   /* Not removed via appearance */
//   margin: 0;
//   font: inherit;
//   color: var(--secondary);
//   width: 1.30em;
//   height: 1.30em;
//   border: 0.15em solid var(--secondary);
//   border-radius: 0.15em;
//   display: grid;
//   transform: translateY(-0.075em);
//   place-content: center;
// }

.inputCheckbox::before {
  content: "";
  width: 0.65em;
  height: 0.65em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em var(--orange-primary);
  transform-origin: bottom left;
  clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.inputCheckbox:checked::before {
  transform: scale(1);
}

//
// ──────────────────────────────────────────
//

//
// ─── REMOVE BORDER WHEN INPUT OR TEXTAREA IS FOCUSED ───────────────────────────────────────
//
textarea:focus,
input:focus {
  outline: none;
}

//
// ──────────────────────────────────────────
//
.overlay-black {
  position: fixed;
  inset: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 10;
}

.modal-close {
  background: black;
  color: white;
  padding: 10px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -10px;
  cursor: pointer;
}